body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input[type='text'],
input[type='password'],
input[type='search'] {
    /* WORKAROUND
  * Fix input shadow style in IOS
  * https://stackoverflow.com/questions/23211656/remove-ios-input-shadow
  */
    -webkit-appearance: none;
}

/*__ utils */
.grow {
    flex: 1 1;
}

.middle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

[role='checkbox'] {
    cursor: pointer;
}

a {
    text-decoration: none;
}
